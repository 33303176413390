import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gaimin-gg',
  template: ''
})
export class GaiminGgComponent {

  constructor() {
    window.location.href = "https://gaimin.gg"
  }
}
