import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthRequestService } from './auth-request.service';
import { AuthStateService } from './auth-state.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private authRequestService: AuthRequestService,
    private authStateService: AuthStateService
  ) { }

  login() {
    const returnURL = btoa(encodeURIComponent(`${window.location.origin}/auth/callback`));
    const redirectURL = `${environment.authModuleUrl}/signin?returnURL=${returnURL}&theme=dark`;

    window.location.href = redirectURL;
  }

  sync() {
    return this.authStateService.getUnverifiedToken$()
      .pipe(
        filter((token) => !!token),
        switchMap((token) => this.authRequestService.checkMigrationStatusRequest(token)),
        filter((response) => response.success),
        switchMap((response) => {
          if (response.data === 'ALREADY_MIGRATED') {
            return of({ success: true });
          }

          if (response.data === 'NOT_MIGRATED') {
            this.logout();

            return of({ success: false });
          }

          if (response.data === 'NEW') {
            return this.authRequestService.createPlatformUserRequest(this.authStateService.unverifiedTokenSnapshot)
          }

          return of ({ success: true });
        }),
        filter((response) => response?.success),
        tap(() => {
          this.authStateService.setAccessToken(this.authStateService.unverifiedTokenSnapshot);
        })
      )
  }

  refreshToken(refreshToken: string) {
    return this.authRequestService.refreshTokenRequest(refreshToken).pipe(
      filter((response) => !!response.success),
      tap((response) => {
        this.authStateService.setAccessToken(response.data!.accessToken);
        this.authStateService.setRefreshToken(response.data!.refreshToken);
      })
    );
  }

  performMeRequest() {
    return this.authRequestService.meRequest()
      .pipe(
        filter((response) => !!response.success),
        tap((response) => {
          this.authStateService.setUser(response?.data || null);
        })
      );
  }

  logout() {
    this.authStateService.clearData();

    window.location.href = `${environment.authModuleUrl}/profile`;
  }
}
