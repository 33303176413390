import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Response, Lead, LeadData, ImpressionResponse, NFTInfo } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class WhitelistedService {
  leadData = {};
  readonly TEMPLATE_TAG: string = 'ELITE_TESTERS';

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
  }

  public getReferralDataFromUrl() {
    const leadData: LeadData = {};
    this.activatedRoute.queryParams.subscribe((res) => {
      if ('token' in res) {
        leadData['referralToken'] = res.token;
      }
      if ('deviceId' in res) {
        leadData['invitingDeviceId'] = res.deviceId;
      }
      this.leadData = leadData;
    });
  }

  createLead(form: object): Observable<Response<Lead>> {
    if (Object.keys(this.leadData).length !== 0) {
      Object.assign(form, { ...this.leadData });
    }
    return this.http.post<Response<Lead>>(`${environment.gaiminApi}/leads`, form);
  }

  trackImpression(): Observable<Response<ImpressionResponse>> {
    return this.http.post<Response<ImpressionResponse>>(
      `${environment.gaiminApi}/statistics/nep/impression`,
      this.leadData
    );
  }

  checkNFT(params: HttpParams): Observable<Response<NFTInfo>> {
    return this.http.get<Response<NFTInfo>>(environment.gaiminApi + '/sc', {
      params
    });
  }

  tagLeadAsTester(form: object): Observable<Response<Lead>> {
    Object.assign(form, { tag: this.TEMPLATE_TAG });
    return this.http.post<Response<Lead>>(`${environment.gaiminApi}/leads/tag`, form);
  }
}
