import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserData } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private unverifiedTokenSrc = new BehaviorSubject<string>(localStorage.getItem('unverifiedToken') || '');
  private accessTokenSrc = new BehaviorSubject<string>(localStorage.getItem('accessToken') || '');
  private refreshTokenSrc = new BehaviorSubject<string>(localStorage.getItem('refreshToken') || '');
  private userSrc = new BehaviorSubject<UserData | null>(null);

  isLoggedIn$ = this.accessTokenSrc.pipe(map((token) => !!token));
  get isLoggedIn(): boolean {
    return !!this.accessTokenSrc.value;
  }

  // Observable getters
  getAccessToken$(): Observable<string> {
    return this.accessTokenSrc.asObservable();
  }

  getRefreshToken$(): Observable<string> {
    return this.refreshTokenSrc.asObservable();
  }

  getUnverifiedToken$(): Observable<string> {
    return this.unverifiedTokenSrc.asObservable();
  }

  getUser$(): Observable<UserData | null> {
    return this.userSrc.asObservable();
  }

  // Synchronous value getters
  get accessTokenSnapshot(): string {
    return this.accessTokenSrc.value;
  }

  get refreshTokenSnapshot(): string {
    return this.refreshTokenSrc.value;
  }

  get unverifiedTokenSnapshot(): string {
    return this.unverifiedTokenSrc.value;
  }

  get userSnapshot(): UserData | null {
    return this.userSrc.value;
  }

  // Setters
  setAccessToken(token: string) {
    localStorage.setItem('accessToken', token);
    this.accessTokenSrc.next(token);
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
    this.refreshTokenSrc.next(token);
  }

  setUnverifiedToken(token: string) {
    localStorage.setItem('unverifiedToken', token);
    this.unverifiedTokenSrc.next(token);
  }

  setUser(user: UserData | null) {
    this.userSrc.next(user);
  }

  clearData() {
    this.setAccessToken('');
    this.setRefreshToken('');
    this.setUnverifiedToken('');
  }
}
