import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {
  @AutoUnsubscribe()
  routeSub = {} as Subscription;
  @AutoUnsubscribe()
  isLoggedInSub = {} as Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authStateService: AuthStateService
  ) { }

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.queryParams
      .pipe(
        tap(params => {
          const refreshTokenParam = params['refreshToken'];
          const accessTokenParam = params['accessToken'];

          if (!accessTokenParam) {
            window.location.href = 'https://gaimin.gg';

            return;
          }

          if (refreshTokenParam) {
            this.authStateService.setRefreshToken(refreshTokenParam);
          }

          if (accessTokenParam) {
            this.authStateService.setUnverifiedToken(accessTokenParam);
          }
        }))
      .subscribe();

    this.isLoggedInSub = this.authStateService.isLoggedIn$
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        tap(() => this.router.navigate(['solana/pfp/stake']))
      )
      .subscribe();
  }

}
