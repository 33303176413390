import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Response, UserData } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestService {
  public refreshEndpoint = `/auth/token/refresh`;

  constructor(
    private http: HttpClient
  ) { }

  refreshTokenRequest(refreshToken: string) {
    return this.http.post<Response<any>>(`${environment.authModuleApi}${this.refreshEndpoint}`,
      {
        refreshToken
      },
      {
        headers: new HttpHeaders().set('skipAuth', 'true')
      }
    );
  }

  checkMigrationStatusRequest(token: string) {
    return this.http.post<Response<'ALREADY_MIGRATED' | 'NOT_MIGRATED' | 'NEW'>>(
      `${environment.gaiminApi}/users/auth/migration_state`,
      {
        gaiminToken: `Bearer ${token}`,
        skipAuth: 'true'
      }
    );
  }

  createPlatformUserRequest(token: string) {
    return this.http.post<Response<string>>(`${environment.gaiminApi}/users/auth/create`, {
      gaiminToken: `Bearer ${token}`,
      skipAuth: 'true'
    });
  }

  meRequest() {
    return this.http.get<Response<UserData>>(`${environment.gaiminApi}/users/me`);
  }
}
