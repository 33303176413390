import { PublicKey } from '@solana/web3.js';
import { mainnet } from '@wagmi/core';
import { publicKey } from '@metaplex-foundation/umi';

export const environment = {
  type: 'prod',
  authModuleUrl: 'http://auth.gaimin.io',
  authModuleApi: 'https://api.auth.gaimin.io/api',
  gaiminApi: 'https://api.gaimin.gg/api',
  downloadPhantomChrome: 'https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa',
  downloadPhantomFireFox: 'https://addons.mozilla.org/en-US/firefox/addon/phantom-app/',
  solana: {
    solanaRPC: 'https://mainnet.helius-rpc.com/?api-key=9a531947-27b1-4cd9-9a60-d7c957a0ee5d',
    solanaCluster: 'mainnet-beta',
    MPL_ACCOUNT_AUTH: {
      pubkey: new PublicKey('auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg'),
      isSigner: false,
      isWritable: false
    },
    MPL_ACCOUNT: {
      pubkey: new PublicKey('2NPzDh1cu1ftiATEc6CFe6489XpabvTJ6qooQ6p9tedo'),
      isSigner: false,
      isWritable: false
    },
    creator: publicKey('Aorbs5ot6Jp47T1BnhDpgFkutd11FpU8qpkZN5FhsB8a')
  },
  genesis: {
    chain: mainnet,
    smartContracts: {
      nfts: '0x67b630e70e793d48f1329c00cad9d60977605df5',
      erc721: '0x9B7a34524FCF8D5ad3AFFf6c691A422eB8eb3607',
      claim: '0x4A116CA93Df31FBa5213BAd8bc198bB4387B029E'
    }
  },
  cloudflareTurnstileSiteKey: '0x4AAAAAAAecXmwaamtnAsuJ'
};
