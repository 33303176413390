import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthStateService } from './shared/services/auth/auth-state.service';
import { WhitelistedService } from './shared/services/whitelisted.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-chat></app-chat>
  `,
  styles: [
    `
      :host {
      }
    `
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private authStateService: AuthStateService,
    private router: Router,
    private whitelistedService: WhitelistedService
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationEnd
        }),
        filter(event => (event as any).url.includes('/auth/callback') || (event as any).url.includes('/solana/pfp/stake')),
        switchMap(() => this.authService.sync()),
        switchMap(() => this.authStateService.isLoggedIn$),
        filter(isLoggedIn => isLoggedIn),
        switchMap(() => this.authService.performMeRequest())
      )
      .subscribe();

      this.whitelistedService.getReferralDataFromUrl();
  }
}
